import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@strava/ui/Avatar';
import styles from './styles.scss';
import EmptyState from '../components/EmptyState';
import ProgressDataView from '../components/ProgressDataView';

const LcLBespokeSegmentProgress = ({
  lclInfo,
  loggedOutText,
  loggedOutBtnLabel,
  loggedOutBkgImage,
  loggedOutBkgImageMobile,
  exploreSegsBtnLabel,
  exploreSegsBtnDestination,
  exploreSegsBkgImage,
  exploreSegsBkgImageMobile,
  exploreSegsText,
  pathColor,
  trailColor,
  effortsLabelSingle,
  effortsLabelMultiple
}) => {
  const { entries, currentAthlete } = lclInfo;
  const isMobileView =
    window.location.pathname.indexOf('local-legends-mobile') > 0;
  const loggedOutBtnDestination = isMobileView
    ? `/login?~channel=lclgtmmobile`
    : `/login?~channel=lclgtm`;

  const avatar = () => {
    return (
      <div className={styles.avatarContainer}>
        <div className={styles.avatar}>
          <Avatar
            name={currentAthlete.name}
            type="athlete"
            size="small"
            src={currentAthlete.avatarSrc}
          />
        </div>
        <div className={styles.name}>{currentAthlete.name}</div>
      </div>
    );
  };

  if (currentAthlete) {
    if (entries && entries.length) {
      return (
        <div className={styles.container}>
          {avatar()}
          <ProgressDataView
            entries={entries}
            pathColor={pathColor}
            trailColor={trailColor}
            labelSingle={effortsLabelSingle}
            labelMultiple={effortsLabelMultiple}
          />
        </div>
      );
    }
    return (
      <div className={styles.container}>
        {avatar()}
        <EmptyState
          text={exploreSegsText}
          buttonLabel={exploreSegsBtnLabel}
          buttonDestination={exploreSegsBtnDestination}
          backgroundImage={exploreSegsBkgImage}
          backgroundImageMobile={exploreSegsBkgImageMobile}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <EmptyState
        text={loggedOutText}
        buttonLabel={loggedOutBtnLabel}
        buttonDestination={loggedOutBtnDestination}
        backgroundImage={loggedOutBkgImage}
        backgroundImageMobile={loggedOutBkgImageMobile}
      />
    </div>
  );
};

LcLBespokeSegmentProgress.defaultProps = {
  pathColor: '#FDC746',
  trailColor: '#DFDFE8',
  exploreSegsBtnDestination: 'https://strava.app.link/lcl-row-explore'
};

LcLBespokeSegmentProgress.propTypes = {
  lclInfo: PropTypes.shape({
    entries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    currentAthlete: PropTypes.shape({
      name: PropTypes.string,
      avatarSrc: PropTypes.string
    })
  }).isRequired,
  loggedOutBtnLabel: PropTypes.string.isRequired,
  loggedOutText: PropTypes.string.isRequired,
  loggedOutBkgImage: PropTypes.string.isRequired,
  loggedOutBkgImageMobile: PropTypes.string.isRequired,
  pathColor: PropTypes.string,
  trailColor: PropTypes.string,
  exploreSegsBtnLabel: PropTypes.string.isRequired,
  exploreSegsBtnDestination: PropTypes.string,
  exploreSegsBkgImage: PropTypes.string.isRequired,
  exploreSegsBkgImageMobile: PropTypes.string.isRequired,
  exploreSegsText: PropTypes.string.isRequired,
  effortsLabelSingle: PropTypes.string.isRequired,
  effortsLabelMultiple: PropTypes.string.isRequired
};

export default LcLBespokeSegmentProgress;
